<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" :rules="rules" class="demo-form-inline form_row" size="small" label-width="200px">
                <el-form-item class="must-fill" label="设备名称" prop="SBMC">
                    <el-input v-model="formdata.saveJson.SBMC" placeholder="请输入设备名称" :maxlength="50" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="设备编码">
                    <el-input v-model="formdata.saveJson.SBBH" placeholder="请输入设备编码" :maxlength="50" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="安装位置">
                    <el-input v-model="formdata.saveJson.AZDWZMS" placeholder="请输入安装位置" :maxlength="50" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="安装位置坐标：">
                    <div style="display: flex; flex-flow: row nowrap">
                        <el-input v-model="jwdValue" :disabled="!isEdit" @change="handleJwdValueChanged" placeholder="示例：116.184776,42.359117"></el-input>
                        <el-button style="marginLeft: 10px"  @click="handleMapPointBtnClick(jwdValue, 'sb', null)">地图选点</el-button>
                    </div>
                </el-form-item>

                <el-tabs class="robot-tabs" v-model="editableTabsValue" type="card" addable :closable="editableTabs.length<2?false:true" @edit="handleTabsEdit">
                    <el-tab-pane :key="item.name" v-for="(item, index) in editableTabs" :label="item.title" :name="item.name">
                        <el-form-item class="must-fill" label="监测点构成" prop="JCDGC">
                            <el-select v-model="item.jcdgcValue" placeholder="请选择监测点构成" :disabled="!isEdit" multiple>
                                <el-option v-for="(item,index) in jcdgcList" :key="index" :label="item.Mc" :value="item.Id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="must-fill" label="监测点名称" prop="JCDMC">
                            <el-input v-model="item.JCDMC" placeholder="请输入监测点名称" :maxlength="50" :disabled="!isEdit" @change="jcwzmcChange"></el-input>
                        </el-form-item>
                        <el-form-item label="遗产要素" prop="YCYSBM">
                            <el-select v-model="item.YCYSBM" placeholder="请选择遗产要素" :disabled="!isEdit" filterable>
                                <el-option v-for="(item,index) in ycysList" :key="index" :label="item.MC" :value="item.BM"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="must-fill" label="安装时间" prop="AZSJ">
                            <el-date-picker v-model="item.AZSJ" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择安装时间" :disabled="!isEdit"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="安装位置">
                            <el-input v-model="item.AZWZ" placeholder="请输入安装位置" :maxlength="50" :disabled="!isEdit"></el-input>
                        </el-form-item>
                        <el-form-item label="安装位置坐标：">
                            <div style="display: flex; flex-flow: row nowrap">
                                <el-input v-model="item.jcdJwdValue" :disabled="!isEdit" @change="handleJwdValueChanged" placeholder="示例：116.184776,42.359117"></el-input>
                                <el-button style="marginLeft: 10px" @click="handleMapPointBtnClick(item.jcdJwdValue, 'jcd', index)">地图选点</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item class="must-fill" label="采集周期" prop="CJZQ">
                            <el-input v-model="item.CJZQ" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" placeholder="请输入采集周期" :disabled="!isEdit"></el-input>
                        </el-form-item>
                        <el-form-item class="must-fill" label="周期单位" prop="ZQDW">
                            <el-select v-model="item.ZQDW" placeholder="请选择周期单位" :disabled="!isEdit">
                                <el-option v-for="(item,index) in zqdwList" :key="item.COLUMNID + index" :label="item.NAME" :value="item.CODE"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="监测指标">
                            <el-select v-model="item.jczbValue" multiple filterable allow-create default-first-option placeholder="请选择监测指标">
                                <el-option v-for="(item, index) in jczbList" :key="index" :label="item.Mc" :value="item.Id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备状态" v-show="!isAdd && item.isZtShow">
                            <el-input v-model="item.SBZT" placeholder="暂无设备状态" :maxlength="50" readonly></el-input>
                        </el-form-item>
                        <el-form-item label="上次抓取时间" v-show="!isAdd && item.isZtShow">
                            <el-date-picker v-model="item.SCZQSJ" placeholder="暂无上次抓取时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" readonly></el-date-picker>
                        </el-form-item>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <sn-dialog :dialogData.sync="dialogDataMapPoint">
                <template>
                    <MapPoint :dataSource="currentData" @handleMapPointClick="handleMapPointClick"></MapPoint>
                </template>
            </sn-dialog>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import MapPoint from "./map_point.vue"

// eslint-disable-next-line no-useless-escape
const longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/; // 经度正则验证
// eslint-disable-next-line no-useless-escape
const latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/; // 纬度正则验证
export default {
    name: "measure_robot_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                    SBMC: "",
                    SBBH: "",
                    AZDWZMS: "",
                    AZDJD: null,
                    AZDWD: null,
                    LX: "",
                },
                heritageId: "",
                itemID: "116",
                userName: "",
                userId: ""
            },
            ycysList: [],
            zqdwList: [],
            sbLx: null,
            itemID: "116",

            //地图选点相关
            jwdValue: "",

            // tabs相关
            rules: {
                // SBMC: [
                //     {
                //         required: true,
                //         message: "请输入设备名称",
                //         trigger: "blur"
                //     }
                // ]
            },
            jcdgcList: [], //监测点构成选择框数据源
            jczbList: [], //监测指标选择框数据源
            jczbNewArr: [], //监测指标创建的新条目
            editableTabsValue: '1',
            editableTabs: [{
                title: '监测点1',
                name: '1',
                jcdgcValue: [], //监测点构成绑定值
                jczbValue: [], //监测指标绑定值
                jcdJwdValue: '', //监测点安装位置坐标
                JCDGC: '', //监测点构成
                JCDMC: '', //监测点名称
                YCYSBM: '', //遗产要素
                AZSJ: '', //安装时间
                AZWZ: '', //安装位置
                JD: null,
                WD: null,
                CJZQ: null, //采集周期
                ZQDW: "", //周期单位
                JCZB: '', //监测指标
                SBZT: "", //设备状态
                SCZQSJ: "", //上次抓取时间
                isZtShow: false,
            }],
            tabIndex: 1,
            dialogDataMapPoint: {
                title: '地图选点',
                dialog: false,
                width: '',
                class: 'deepBlueDialog',
                action: false,
            },
            currentData:{
                pointValue: '',
                type: '',
                index: null
            },
        };
    },
    components: {
        MapPoint,
    },
    computed: {},
    watch: {
        "formdata.saveJson.cnsxlylx": function() { }
    },
    mounted() {
        this.sbLx = this.$route.query.sbLx;
        if (!this.formdata.saveJson.LX) {
            this.formdata.saveJson.LX = this.sbLx;
        }
        this.getYcysList();
        this.getZqdwList();
        this.getJczbList();
        this.getJcdgcList();
    },
    methods: {
        ...mapActions([
            "getYcysdtList", // 获取遗产要素列表
            "GetJCDGCList", //获取监测点构成列表
            "GetZBXList", //获取监测指标列表
            "SaveZBX", //保存服务(监测指标项)
            "SaveCLJQR", //保存服务
            "DetailsCLJQR", //获取详情
            "GetZqdwList", // 获取周期单位列表
            "DeleteCLJQR", //删除服务
        ]),
        handleTabsEdit(targetName, action) {
            if (action === 'add') {
                if(this.tabIndex < this.editableTabs.length) {
                    this.tabIndex = this.editableTabs.length;
                }
                let newTabName = ++this.tabIndex + '';
                this.editableTabs.push({
                    title: `监测点${newTabName}`,
                    name: newTabName,
                    isZtShow: false
                });
                this.editableTabsValue = newTabName;
            }
            if (action === 'remove') {
                let tabs = this.editableTabs;
                let activeName = this.editableTabsValue;
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name;
                            }
                        }
                    });
                }
                let currentID = ''
                tabs.forEach((tab) => {
                    if (tab.name === targetName) {
                        currentID = tab.ID;
                    }
                });
                if(currentID){
                    let tabData = {
                        activeName:activeName,
                        tabs:tabs,
                        targetName:targetName
                    }
                    this.deleteData(currentID, tabData);
                }else{
                    this.editableTabsValue = activeName;
                    this.editableTabs = tabs.filter(tab => tab.name !== targetName);
                }
            }
        },
        // 删除数据
        deleteData(id,tabData) {
            let _this = this;
            let checkBox = id;
            _this
                .$confirm('是否永久删除该条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            _this.delData(checkBox, done, instance,tabData);
                        } else {
                            done();
                        }
                    }
                })
                .then(() => {})
                .catch(() => {
                    _this.$message({
                        message: '已取消删除',
                        type: 'info'
                    });
                });
        },
        async delData(id, done, instance,tabData) {
            let res = await this.DeleteCLJQR({
                id: id
            });
            this.delOver(res, done,tabData);
        },
        delOver(res, done,tabData) {
            if (res.IsSuccess) {
                done();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.editableTabsValue = tabData.activeName;
                this.editableTabs = tabData.tabs.filter(tab => tab.name !== tabData.targetName);
                // this.tableGroupAttributes.snPage.currentPage = 1;
                // this.getTableData();
            } else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                });
            }
        },

        //监测位置名称改变对应的标题改变
        jcwzmcChange() {
            this.editableTabs[
                Number(this.editableTabsValue) - 1
            ].title = this.editableTabs[
                Number(this.editableTabsValue) - 1
            ].JCDMC;
        },
        // 获取遗产要素列表
        async getYcysList() {
            this.ycysList = [];
            let result = await this.getYcysdtList();
            if (result && result.length > 0) {
                this.ycysList = result;
            }
        },
        // 获取周期单位列表
        async getZqdwList() {
            this.zqdwList = [];
            let result = await this.GetZqdwList();
            if (result && result.length > 0) {
                this.zqdwList = result;
            }
        },
        // 获取监测点构成列表
        async getJcdgcList() {
            this.jcdgcList = [];
            let result = await this.GetJCDGCList();
            if (result && result.length > 0) {
                this.jcdgcList = result;
            }
        },
        // 获取监测指标列表
        async getJczbList() {
            this.jczbList = [];
            let result = await this.GetZBXList();
            if (result && result.length > 0) {
                this.jczbList = result;
            }
        },
        async getFromData() {
            let _this = this;
            this.editableTabs = [];
            let params = {
                id: this.id
            };
            let result = await this.DetailsCLJQR(params);
            if (result) {
                if(result.SBQD){
                    Object.assign(this.formdata.saveJson, result.SBQD);
                    if (
                        this.formdata.saveJson.AZDJD &&
                        this.formdata.saveJson.AZDWD
                    ) {
                        this.jwdValue = `${this.formdata.saveJson.AZDJD},${this.formdata.saveJson.AZDWD}`;
                    }
                }
                if(result.CLJQR && result.CLJQR.length>0){
                    result.CLJQR.forEach((item,index) => {
                        item.title = item.JCDMC;
                        item.name = (index + 1).toString();
                        item.jcdgcValue = item.JCDGC ? item.JCDGC.split(",") : [];
                        item.jczbValue = item.JCZB ? item.JCZB.split(",") : [];
                        item.jcdJwdValue = (item.JD && item.WD) ? `${item.JD},${item.WD}` : '';
                        item.isZtShow = true;
                        if(item.ID === _this.$route.query.jcdId){
                            _this.tabIndex = index + 1;
                            _this.editableTabsValue = item.name;
                        }
                    })
                    this.editableTabs = result.CLJQR;
                }
            }
        },
        // 返回列表
        returnList() {
            this.$router.push({
                name: "equipmanage_grid",
                query: {
                    sbLx: this.sbLx,
                    activeName: this.$route.query.activeName
                }
            });
        },
        // 保存暂不提交未做验证
        async saveNosubmit() {
            let _this = this;
            // _this.formdata.saveJson.shzt = 1;
            if(!_this.formdata.saveJson.SBMC){
                _this.$message({
                    type: 'warning',
                    message: '请检查是否填写完整！'
                });
            }else{
                let canSave = true;
                this.jczbNewArr = [];
                _this.editableTabs.forEach(item=>{
                    // 监测点构成字段处理
                    if (item.jcdgcValue && item.jcdgcValue.length > 0) {
                        item.JCDGC = item.jcdgcValue.toString();
                    }
                    if(!item.JCDGC || !item.JCDMC || !item.AZSJ || !item.CJZQ || !item.ZQDW){
                        canSave = false;
                    }
                    if(canSave){
                        // 监测指标字段处理
                        if(item.jczbValue && item.jczbValue.length > 0) {
                            // 遍历监测指标下拉框数据源，如果数据源里不存在新输入的绑定值，先调保存下拉框数据源服务，再调保存数据服务
                            _this.handleJczbItem(item.jczbValue);
                        }
                    }
                })
                if(!canSave){
                    _this.$message({
                        type: 'warning',
                        message: '请检查是否填写完整！'
                    });
                }else{
                    console.log(3333, _this.jczbNewArr);
                    // _this.jczbNewArr 是剩余的数组
                    // 此处调服务，保存数组里的项:
                    let jczbNewArr = [];
                    let strjsonStr = '';
                    let jczbRes = null;
                    if(_this.jczbNewArr && _this.jczbNewArr.length>0){
                        _this.jczbNewArr.forEach(jczbEle=>{
                            let tmpItem = {
                                Mc: jczbEle
                            }
                            jczbNewArr.push(tmpItem);
                        })
                        strjsonStr = JSON.stringify(jczbNewArr);
                        jczbRes = await _this.SaveZBX({
                            strjson: strjsonStr
                        })
                    }
                    if((strjsonStr && jczbRes.IsSuccess) || !strjsonStr){
                        // 此处调获取监测指标列表的服务，遍历替换
                        if(strjsonStr && jczbRes.IsSuccess){
                            _this.jczbList = [];
                            let jczbRes = await this.GetZBXList();
                            if (jczbRes && jczbRes.length > 0){
                                _this.jczbList = jczbRes;
                            }
                        }
                        _this.editableTabs.forEach(item=>{
                            // 监测指标字段处理
                            if(item.jczbValue && item.jczbValue.length > 0) {
                                for(let i = 0; i < item.jczbValue.length; i++){
                                    if(_this.jczbList && _this.jczbList.length>0){
                                        _this.jczbList.forEach(jczbListItem=>{
                                            if(item.jczbValue[i] === jczbListItem.Mc){
                                                item.jczbValue[i] = jczbListItem.Id;
                                            }
                                        })
                                    }
                                }
                                item.JCZB = item.jczbValue.toString();
                            }
                        })
                        _this.saveData();
                    }
                }
            }
        },
        // 处理监测指标创建的新条目
        handleJczbItem(val){
            let _this = this;
            if (val && val.length > 0) {
                if (_this.jczbList && _this.jczbList.length > 0) {
                    let newOptions = [];
                    _this.jczbList.forEach(item => {
                        newOptions.push(item.Id);
                        newOptions.push(item.Mc);
                    })
                    val.forEach(ele => {
                        if (newOptions.indexOf(ele) == -1 && _this.jczbNewArr.indexOf(ele) == -1) {
                            _this.jczbNewArr.push(ele);
                        }
                    })
                } else {
                    _this.jczbNewArr = val;
                }
            }
        },
        async saveData() {
            let sbSbqdObj = {
                "Id": this.formdata.saveJson.ID || '',
                "Sbmc": this.formdata.saveJson.SBMC,
                "Lx": this.formdata.saveJson.LX,
                "Sbbh": this.formdata.saveJson.SBBH,
                "Azdwzms": this.formdata.saveJson.AZDWZMS,
                "Azdjd": this.formdata.saveJson.AZDJD,
                "Azdwd": this.formdata.saveJson.AZDWD
            }
            let cljqrArr = [];
            if(this.editableTabs && this.editableTabs.length>0){
                this.editableTabs.forEach(item=>{
                    let cljqrItem = {
                        "Id": item.ID || '',
                        "Jcdgc": item.JCDGC,
                        "Jcdmc": item.JCDMC,
                        "Ycysbm": item.YCYSBM,
                        "Azsj": item.AZSJ,
                        "Azwz": item.AZWZ,
                        "JD": item.JD,
                        "WD": item.WD,
                        "Cjzq": item.CJZQ,
                        "Zqdw": item.ZQDW,
                        "JCZB": item.JCZB,

                    }
                    cljqrArr.push(cljqrItem);
                })
            }
            let res = await this.SaveCLJQR({
                strjson: {
                    sbSbqd: this.isAdd ? sbSbqdObj : this.formdata.saveJson,
                    cljqr: this.isAdd ? cljqrArr : this.editableTabs
                }
            });
            if (res.IsSuccess) {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.returnList();
            }
        },

        // 经纬度输入框改变
        handleJwdValueChanged(val) {
            if (val) {
                let jwdArr = val.split(",");
                if (jwdArr && jwdArr.length === 2 && jwdArr[0] && jwdArr[1]) {
                    let lng = jwdArr[0];
                    let lat = jwdArr[1];

                    if (longrg.test(lng) && latreg.test(lat)) {
                        this.formdata.saveJson.AZDJD = lng;
                        this.formdata.saveJson.AZDWD = lat;
                    } else {
                        this.openJwdTip();
                    }
                } else {
                    this.openJwdTip();
                }
            }
        },
        openJwdTip() {
            this.$message({
                type: "warning",
                message: "请填写正确的经纬度"
            });
        },
        // 点击地图选点按钮
        handleMapPointBtnClick(pointValue,type,index){
            this.dialogDataMapPoint.dialog = true;
            this.currentData = {
                pointValue: pointValue,
                type: type,
                index: index
            }
        },
        // 地图选点监听事件
        handleMapPointClick(val){
            if(val.type === 'sb'){
                this.formdata.saveJson.AZDJD = val.pointArr[0];
                this.formdata.saveJson.AZDWD = val.pointArr[1];
                this.jwdValue = `${val.pointArr[0]},${val.pointArr[1]}`;
            }else if(val.type === 'jcd'){
                this.editableTabs[val.index].JD = val.pointArr[0];
                this.editableTabs[val.index].WD = val.pointArr[1];
                this.editableTabs[val.index].jcdJwdValue = `${val.pointArr[0]},${val.pointArr[1]}`;
                this.$forceUpdate()
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.titlediv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 25px;
}

.leftline {
    width: 180px; // width: calc(50% - 108px);
    height: 0px;
    border-bottom: 1px solid #ccc;
}

.subtitle {
    width: 155px;
    height: 30px;
    background-color: #1862ad;
    border-radius: 15px;
    color: #fff;
    line-height: 30px;
    margin: 0 30px;
    text-align: center;
}

.robot-tabs{
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 60px;
}
</style>
